import React from 'react';
import ShareWidget from './ShareWidget';

export default function BookmarkWidget3({ slug, recipe, title }) {
  const urlPart = recipe ? `recetas/${slug}` : slug;
  const urlShare = `/${urlPart}`;

  return (
    <div className="flex flex-row items-center">
      <span className="mr-6 w-24">
        <ShareWidget url={urlShare} title={title} />
      </span>
    </div>
  );
}
