import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MessagePanel from './MessagePanel';
import { useSendForm } from '../../utils/useSendForm';

const SideOptin2 = () => {
  const {
    email,
    sending,
    error,
    success,
    onEmailChange,
    submitForm,
  } = useSendForm();

  return (
    <section className="text-gray-600 body-font">
      <Card>
        <CardContent>
          <div className="bg-themeLighterAlt rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
            <h2 className="text-gray-700 text-lg font-medium title-font mb-5 text-center">
              Únete a nuestra comunidad y recibe <u>exclusivo</u> nuestro menú
              de 7 días&nbsp;
            </h2>
            <form action="" className="relative mb-4" onSubmit={submitForm}>
              <input
                className="w-full bg-white border-2 border-themeBrandColor border-solid  focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 my-4 leading-8 transition-colors duration-200 ease-in-out"
                type="email"
                name="email"
                id="email-input"
                placeholder="Email"
                value={email}
                onChange={onEmailChange}
                disabled={sending}
              />
              <button
                className="w-full rounded border-2 border-themeBrandColor border-solid bg-themeBrandColor hover:bg-white text-white hover:text-themeBrandColor py-2 px-8 focus:outline-none rounded text-lg"
                type="submit"
              >
                Suscribirme
              </button>
            </form>
            <MessagePanel error={error} sending={sending} success={success} />
          </div>
        </CardContent>
      </Card>
    </section>
  );
};

export default SideOptin2;
