import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import CookTime from '../assets/cook-time.svg';
import Portions from '../assets/portions.svg';
import PrepTime from '../assets/prep-time.svg';
import RecipeBttmDsktp from '../components/Advertising/RecipeBttmDsktp';
import RecipeBttmMob from '../components/Advertising/RecipeBttmMob';
import TopHomepage from '../components/Advertising/TopHomepage';
import TopHomepageMob from '../components/Advertising/TopHomepageMob';
import BookmarkWidget3 from '../components/BookmarkWidget3';
import Hero from '../components/Hero';
import JsonBread from '../components/Json/JsonBread';
import Layout from '../components/Layout';
import SeoRecipe from '../components/SEO/SeoRecipe';
import SubscribeSide from '../components/Subscribe/SideOptIn2';
import SubscribePost from '../components/Subscribe/PostOptin';
import Halfpage from '../components/Advertising/Halfpage';
import Sidebar1Desktop from '../components/Advertising/Sidebar1Desktop';
import Top5Posts from '../components/Top5Posts';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '2rem',
    '@media (min-width: 375px)': {
      maxWidth: '325px',
    },
    '@media (min-width: 640px)': {
      marginRight: '0.5rem',
      '&:last-child': {
        marginRight: 0,
      },
    },

    '@media (min-width: 768px)': {
      marginTop: 0,
      marginRight: '1rem',
      '&:last-child': {
        marginRight: 0,
      },
    },
    '@media (min-width: 1024px)': {
      marginRight: '2rem',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  card: {
    width: '100%',
    height: '100%',
    '@media (min-width: 375px)': {
      maxWidth: '325px',
    },
  },
  media: {
    height: 180,
  },
});

const RecipeTemplate1 = ({ data }) => {
  const { recipesdata, pageViews } = data;

  const classes = useStyles();

  const recipeIngredient = recipesdata.data.ingredientes.map(
    ({ ingrediente_name }) => ingrediente_name
  );

  const tags = recipesdata.data.tags.map(({ tag: { slug } }) => slug);

  const recipeSteps = recipesdata.data.instrucciones.map(
    ({ instruccione: { text } }) => text
  );

  const totalTime = recipesdata.data.preptime + recipesdata.data.cooktime;

  let res = recipeSteps.reduce((accum, item, i) => {
    if (i === recipeSteps.length - 1) return accum;
    accum.push({ '@type': 'HowToStep', text: item });
    return accum;
  }, []);

  const schemaBaseRecipe = {
    '@context': 'https://schema.org/',
    '@type': 'Recipe',
    name: `${recipesdata.data.title1.text}`,
    image: `${recipesdata.data.image.url}`,
    description: `Receta de ${recipesdata.data.title1.text}`,
    //keywords: Object.values(recipesdata.data.tags),
    author: {
      '@type': 'Person',
      name: 'Dediabetes',
    },
    datePublished: recipesdata.data.date,
    recipeCategory: `Recetas para Diabeticos`,
    prepTime: `PT${recipesdata.data.preptime}M`,
    cookTime: `PT${recipesdata.data.cooktime}M`,
    totalTime: `PT${totalTime}M`,
    recipeYield: `${recipesdata.data.servings1}`,
    nutrition: {
      '@type': 'NutritionInformation',
      calories: `${recipesdata.data.calories1} cal`,
      fatContent: `${recipesdata.data.fat1} g`,
      proteinContent: `${recipesdata.data.protein1} g`,
      carbohydrateContent: `${recipesdata.data.carb1} g`,
      fiberContent: `${recipesdata.data.fiber1} g`,
    },
  };

  const schemaRecipe = Object.assign(
    schemaBaseRecipe,
    { keywords: tags },
    { recipeIngredient: recipeIngredient },
    { recipeInstructions: res }
  );

  const descSEO =
    recipesdata.data.description.text === ''
      ? `Receta de ${recipesdata.data.title1.text}`
      : recipesdata.data.description.text;

  const fullRecipe = (
    <article className="col-span-2 md:col-span-1 mx-autoleading-losse my-6 xl:px-24">
      <div className="flex flex-col bg-white p-6 md:px-6 lg:px-12">
        <div className="flex flex-col">
          <div className="flex flex-col ">
            <div>
              <span className="flex flex-row justify-start m-0 p-0 leading-tight">
                {tags.map(item => (
                  <ul key={item} className="my-0 mr-4 py-0 list-none ml-0">
                    <li>
                      <button className="border border-gray-500 rounded hover:bg-themeBrandColor text-themeBrandColor hover:text-white px-2 text-base">
                        <Link
                          to={`/recetas/${item}/`}
                          data-typesense-field="recipe-type"
                        >
                          {item}
                        </Link>
                      </button>
                    </li>
                  </ul>
                ))}
              </span>
              <div className="hidden" data-typesense-field="type">
                Recetas
              </div>
              {recipesdata.data.image.url ? (
                <span className="hidden" data-typesense-field="image">
                  {recipesdata.data.image.url}
                </span>
              ) : (
                <span className="hidden" data-typesense-field="image" />
              )}

              <span className="hidden" data-typesense-field="article_year" />
              <span className="hidden" data-typesense-field="article_date" />
              <span className="hidden" data-typesense-field="tag_article" />
              <span className="hidden" data-typesense-field="article_author" />
              <span
                className="hidden"
                data-typesense-field="article_author_image"
              />
              <div className="hidden" data-typesense-field="advice_category" />
              <span className="hidden" data-typesense-field="advice_source_n" />
              <span className="hidden" data-typesense-field="advice_source_d" />
              <span className="hidden" data-typesense-field="advice_ref_url" />
              <h1
                className="m-0 p-0 leading-tight capitalize"
                data-typesense-field="title"
              >
                {recipesdata.data.title1.text}
              </h1>
            </div>
            <span data-typesense-field="description">
              {recipesdata.data.description.text && (
                <p className="italic my-4">
                  {recipesdata.data.description.text}{' '}
                  {pageViews && pageViews.allTimeViews
                    ? `• ${pageViews.allTimeViews} views`
                    : ''}
                </p>
              )}
            </span>
          </div>
          <div className="flex flex-row leading-tight">
            <BookmarkWidget3
              slug={recipesdata.data.slug}
              title={recipesdata.data.title1.text}
              recipe
            />
          </div>

          <div />
        </div>
      </div>
      <div className="overflow-hidden leading-3 pb-8 md:ml-6 lg:ml-12">
        <GatsbyImage
          image={recipesdata.data.image.gatsbyImageData}
          className="w-full overflow-hidden"
          alt={recipesdata.data.title1.text}
        />
      </div>
      <div className="border-4 md:ml-6 lg:ml-12 md:p-4 lg:pl-12">
        <div className="grid grid-cols-3 mt-4 divide-x-4">
          <div className="my-0">
            <img
              src={PrepTime}
              alt="Tiempo de preparación"
              className="h-10 mx-auto"
            />
            <p className="text-center my-0">Preparación</p>
            <p className="text-center my-0">{recipesdata.data.preptime} min.</p>
          </div>
          <div className="">
            <img
              src={CookTime}
              alt="Tiempo de coccion"
              className="h-10 mx-auto"
            />
            <p className="text-center my-0">Cocción</p>
            <p className="text-center my-0">{recipesdata.data.cooktime} min.</p>
          </div>
          <div className="">
            <img src={Portions} alt="Porciones" className="h-10 mx-auto" />
            <p className="text-center my-0">Porciones</p>
            <p className="text-center my-0">{recipesdata.data.servings1}</p>
          </div>
        </div>

        <h3 className="text-center my-4">
          Información Nutricional (por porción)
        </h3>

        <div className="grid md:grid-cols-2 pl-2">
          <div>
            <p className="my-2">Calorías: {recipesdata.data.calories1} Kcal</p>
            <p className="my-2">Grasas: {recipesdata.data.fat1} g</p>
            <p className="my-2">Carbohidratos: {recipesdata.data.carb1}g</p>
            <p className="my-2">Fibras: {recipesdata.data.fiber1}g</p>
          </div>
          <div>
            <p className="my-2">Proteínas: {recipesdata.data.protein1} g </p>
            <p className="my-2">Vitamina A: {recipesdata.data.VitaminaA} ug </p>
            <p className="my-2">Magnesio: {recipesdata.data.Magnesio} mg </p>
            <p className="my-2">Rac Carb: {recipesdata.data.racCarb}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col max-w-full-xl mx-auto m-0 hidden">
        <p>{recipesdata.data.descriptionB}</p>
      </div>
      <div className="md:flex flex-row justify-center m-6 hidden md:visible">
        <TopHomepage />
      </div>
      <div className="flex flex-row justify-center mt-6 md:hidden ">
        <TopHomepageMob />
      </div>
      <div className="flex flex-row justify-center mt-6 md:hidden ">
        <SubscribePost />
      </div>
      <div className="pl-6 md:pl-6 lg:pl-12 leading-loose">
        <h2 className="border-b-2 border-gray-400">Ingredientes</h2>

        <div>
          {recipesdata.data.ingredientes.map((item, i) => (
            <ul key={i} className="m-0">
              <li className="list-none my-0">{item.ingrediente_name}</li>
            </ul>
          ))}
        </div>

        <div className="mb-10">
          <h2 className="border-b-2 border-gray-400">Preparación</h2>
          <table className="table-fixed">
            {recipesdata.data.instrucciones.map((item, index) => (
              <tr key={index} className="hover:bg-themeLighter">
                <td className="w-1/12 font-bold">{index + 1}</td>

                <td id={`Paso#${item.id}`} className="my-4">
                  {item.instruccione.text}
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className="flex flex-row leading-tight">
          <BookmarkWidget3
            slug={recipesdata.data.slug}
            title={recipesdata.data.title1.text}
            recipe
          />
        </div>

        <div className="md:flex flex-row justify-center m-6 hidden md:visible">
          <RecipeBttmDsktp />
        </div>
        <div className="flex flex-row justify-center mt-6 md:hidden ">
          <RecipeBttmMob />
        </div>
        <div className="flex justify-center">
          <button className="border border-themeBrandColor rounded-full hover:bg-themeBrandColor hover:text-white  mt-4 flex justify-center">
            <Link
              to="/recetas"
              className="link text-themeBrandColor hover:text-white py-1 px-2"
            >
              REGRESAR AL RECETARIO
            </Link>
          </button>
        </div>
      </div>
    </article>
  );

  return (
    <Layout>
      <SeoRecipe
        title={`${recipesdata.data.title1.text}`}
        date={recipesdata.data.date}
        image={recipesdata.data.image.url}
        description={descSEO}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaRecipe)}
        </script>
      </Helmet>
      <JsonBread
        title={`${recipesdata.data.title1.text}`}
        category={`recetas`}
        slug={`recetas/${recipesdata.data.slug}`}
      />
      <Hero />

      <div className="grid grid-cols-blog w-full container mx-auto gap-x-4">
        {fullRecipe}

        <aside className=" hidden md:block md:visible mx-autoleading-losse bg-white my-6">
          <SubscribeSide />
          <div className="">
            <Sidebar1Desktop />
          </div>

          <Top5Posts />
          <div className="my-3">
            <Halfpage />
          </div>
        </aside>
      </div>
      <section className="mt-8 border-t-8 border-themeBrandColor">
        <h2 className="text-center my-8">Otras Recetas</h2>
        <div className="m-4 sm:mx-6 flex flex-col md:flex-row items-center justify-center md:items-stretch max-w-full-xl xl:mx-auto">
          {recipesdata.data.related_recipes.length > 0 &&
            recipesdata.data.related_recipes.map((rel, i) => {
              return (
                <Link
                  to={`/recetas/${rel.related_recipe.uid}`}
                  className={classes.root}
                  key={i}
                >
                  <Card className={classes.card}>
                    <CardActionArea style={{ outline: 'none' }}>
                      <CardMedia className={classes.media}>
                        <GatsbyImage
                          image={
                            rel.related_recipe.document.data.image
                              .gatsbyImageData
                          }
                          className={classes.media}
                          alt={rel.related_recipe.document.data.title1.text}
                        />
                      </CardMedia>
                      <CardContent>
                        <h3>{rel.related_recipe.document.data.title1.text}</h3>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              );
            })}
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query Recetas($slug: String, $fullSlug: String) {
    recipesdata: prismicRecipes(uid: { eq: $slug }) {
      slug: uid
      data {
        related_recipes {
          related_recipe {
            id
            uid
            document {
              ... on PrismicRecipes {
                id
                data {
                  title1 {
                    text
                  }
                  image {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
        tags {
          tag {
            slug
          }
        }
        title1 {
          text
        }
        date
        ingredientes {
          ingrediente_name
        }
        instrucciones {
          instruccione {
            text
          }
        }
        description {
          text
          html
        }
        descriptionb {
          text
          html
        }
        preptime
        cooktime
        servings1
        calories1
        carb1
        protein1
        fat1
        fiber1
        raccarb
        magnesio
        vitaminaa
        vitamin_a
        image {
          gatsbyImageData(layout: CONSTRAINED)
          url
        }
      }
    }
    pageViews(id: { eq: $fullSlug }) {
      allTimeViews
    }
  }
`;

export default RecipeTemplate1;
